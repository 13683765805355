<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Pagos de nómina de documento de {{ typeName }}
    </h1>

    <div class="invoice-div-bottom-div">
      <div class="table-header providers-list-global table-header-border">
        Cantidad de pagos: <b>{{ currentPayments.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="currentPayments"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="retentionDetails"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="DIAS"
      >
        <template #empty>
          <div class="has-text-centered">No hay pagos de nómina</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "SatDocumentsPayroll",
  props: ["payment", "typeName"],
  data() {
    return {
      currentObjects: [],
      currentMovements: [],
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "DIAS",
          label: "Días",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TIPO",
          label: "Tipo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CANTIDAD",
          label: "Cantidad",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "Version",
          label: "Versión",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formattedStartDate",
          label: "Fecha de inicio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formattedEndDate",
          label: "Fecha de fin",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formattedDate",
          label: "Fecha de pago",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  mounted() {
    moment.locale("es");
  },
  methods: {},
  computed: {
    currentPayments() {
      const paymentWithProps = { ...this.payment };
      paymentWithProps.formattedStartDate = moment(
        this.payment.FECHA_INICIO
      ).format("DD-MM-YYYY HH:mm");
      paymentWithProps.formattedEndDate = moment(this.payment.FECHA_FIN).format(
        "DD-MM-YYYY HH:mm"
      );
      paymentWithProps.formattedDate = moment(this.payment.FECHA_PAGO).format(
        "DD-MM-YYYY HH:mm"
      );
      return [paymentWithProps];
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
