/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Conectar cuenta del SAT</h1>
    <div id="belvo"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { checkForPermissions, returnAxiosHeaders } from "../../../utils/fns";
import { serverUrl } from "../../../utils/env-variables-fns";
import axios from "axios";
export default {
  name: "ConnectSatModal",
  components: {},
  props: ["linkId"],
  data() {
    return {
      moment: moment,
      link: "",
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    this.connectBankAccount();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async createWidget() {
      if (
        checkForPermissions(
          this.$store.getters.USER.permissions.permissions,
          "accounting",
          "connectSatAccount"
        )
      ) {
        const getAccessToken = async () => {
          try {
            this.$store.commit("SET_LOADING", true);
            let response = await axios.post(
              `${serverUrl}/belvo/connect-account`,
              {
                companyMainName: this.companyMainName,
                email: this.email,
                linkId: this.linkId,
              },
              returnAxiosHeaders(this.token)
            );
            this.$store.commit("SET_LOADING", false);
            return response.data;
          } catch (error) {
            this.$store.commit("SET_LOADING", false);
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al conectar con el proveedor del SAT",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        };
        const successCallbackFunction = async (link) => {
          try {
            // Save mode
            this.link = link;
            this.$store.commit("SET_LOADING", true);
            let accountsResponse = await axios.post(
              this.linkId
                ? `${serverUrl}/accounting/update-link`
                : `${serverUrl}/accounting/save-link`,
              {
                companyMainName: this.companyMainName,
                email: this.email,
                link,
              },
              returnAxiosHeaders(this.token)
            );
            this.$store.commit(
              this.linkId ? "UPDATE_SAT_LINK" : "SET_SAT_LINK",
              accountsResponse.data
            );
            this.$store.commit("SET_LOADING", false);
            if (accountsResponse.status === 200) {
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message: "Se conecto exitosamente la cuenta del SAT",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                onConfirm: () => {
                  this.closeModal();
                },
                cancelText: "cancelar",
                canCancel: false,
              });
            } else {
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al conectar la cuenta del SAT con la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
          } catch (error) {
            this.$store.commit("SET_LOADING", false);
          }
        };
        const onExitCallbackFunction = (data) => {};
        const onEventCallbackFunction = (data) => {};
        const config = {
          company_name: "Coconut Control",
          company_benefit_header:
            "Conexión directa con el SAT para tener la última información disponible",
          company_benefit_content:
            "Obten información de los documentos que el SAT tiene de tu empresa. Consulta de información y de XML",
          opportunity_loss:
            "No pierdas la oportunidad de tener la ultima información dispnible en el SAT",
          country_codes: ["MX"],
          institution_types: ["fiscal"],
          locale: "es",
          callback: (link) => successCallbackFunction(link),
          onExit: (data) => onExitCallbackFunction(),
          onEvent: (data) => onEventCallbackFunction(),
        };
        const { access } = await getAccessToken();
        window.belvoSDK.createWidget(access, config).build();
      } else {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "No cuentas con permisos suficientes para realizar esta acción",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.closeModal(),
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    loadScript(src) {
      const node = document.createElement("script");
      node.src = src;
      node.type = "text/javascript";
      node.async = true;
      node.onload = this.createWidget;
      document.body.appendChild(node);
    },
    connectBankAccount() {
      this.loadScript("https://cdn.belvo.io/belvo-widget-1-stable.js");
    },
  },
  computed: {
    companyMainName() {
      return this.$store.getters.COMPANY_INFORMATION.name;
    },
    email() {
      return this.$store.getters.USER.email;
    },
    token() {
      return this.$store.getters.USER.token;
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
