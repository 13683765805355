<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Detalles de documento de {{ typeName }}
    </h1>

    <div class="invoice-div-bottom-div">
      <div class="table-header providers-list-global table-header-border">
        Cantidad de detalles: <b>{{ currentDetails.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="currentDetails"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="documentDetails"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información del detalle`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="DESCRIPCION"
      >
        <template #empty>
          <div class="has-text-centered">No hay detalles</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div
                  class="table-header providers-list-global table-header-border"
                >
                  Cantidad de retenciones:
                  <b>{{ props.row.RETENCIONES.length }}</b>
                </div>
                <b-table
                  pagination-position="both"
                  :data="props.row.RETENCIONES"
                  :striped="true"
                  :bordered="true"
                  :hoverable="true"
                  ref="retentionDetails"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort-direction="asc"
                  checkbox-position="left"
                  scrollable
                  default-sort="NOMBRE_IMP"
                >
                  <template #empty>
                    <div class="has-text-centered">No hay retenciones</div>
                  </template>

                  <template slot-scope="props">
                    <b-table-column
                      v-for="column in retentionsColumns"
                      searchable
                      sortable
                      v-bind="column"
                      :key="column.field"
                      :visible="column.display"
                      :label="column.label"
                    >
                      <span v-if="!checkIfValid(props.row[column.field])">{{
                        "Sin información"
                      }}</span>
                      <span v-if="column.money">{{
                        props.row[column.field] | money("MXN", 2)
                      }}</span>
                      <span v-if="!column.money">{{
                        props.row[column.field]
                      }}</span>
                    </b-table-column>
                  </template>
                </b-table>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "SatDoumentsDetails",
  props: ["details", "typeName"],
  data() {
    return {
      currentObjects: [],
      currentMovements: [],
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ID_PRODUCTO",
          label: "Id de producto",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CANTIDAD",
          label: "Cantidad",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION_UNI",
          label: "Unidad",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "COSTO_UNITARIO",
          label: "Costo unitario",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "COSTO_ANTES_IMP",
          label: "Subtotal",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "PORC_IMPUESTOS",
          label: "Porcentaje de impuestos",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TOTAL_IMPUESTOS",
          label: "Total de impuestos",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      retentionsColumns: [
        {
          field: "NOMBRE_IMP",
          label: "Nombre de impuesto",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PORC_IMPUESTOS",
          label: "Porcentaje de impuesto",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "RET_TOTAL",
          label: "Total retenido",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
    };
  },
  mounted() {
    moment.locale("es");
  },
  methods: {},
  computed: {
    currentDetails() {
      return this.details.map((singleDetail) => {
        const singleDetailWithProps = { ...singleDetail };
        return singleDetailWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
