var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_c('div',{staticClass:"global-lists-search"},[_c('div',{staticClass:"global-lists-filters-columns"},_vm._l((_vm.columns),function(singleColumn){return _c('b-checkbox',{key:singleColumn.label,model:{value:(singleColumn.display),callback:function ($$v) {_vm.$set(singleColumn, "display", $$v)},expression:"singleColumn.display"}},[_vm._v(" "+_vm._s(singleColumn.label)+" ")])}),1),_c('div',{staticClass:"global-lists-filters-container"},[_c('b-field',{staticClass:"no-margin",attrs:{"label":"Fecha de inicio","label-position":"inside","type":{ 'is-danger': _vm.errors.startDate },"message":{
          'La fecha de inicio no es valida': _vm.errors.startDate,
        }}},[_c('b-datepicker',{staticClass:"global-lists-filters-control",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha de inicio","icon":"calendar-today","trap-focus":""},on:{"input":function (value) { return _vm.changeDateFilterValue(value); }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('b-field',{staticClass:"no-margin",attrs:{"label":"Fecha de fin","label-position":"inside","type":{ 'is-danger': _vm.errors.endDate },"message":{
          'La fecha de fin no es valida': _vm.errors.endDate,
        }}},[_c('b-datepicker',{staticClass:"global-lists-filters-control",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha de fin","icon":"calendar-today","trap-focus":""},on:{"input":function (value) { return _vm.changeDateFilterValue(value); }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('div',[_c('h3',{staticClass:"documents-title"},[_vm._v(" Estatus de conexión con el SAT: "),(!_vm.satLink)?_c('b',{staticClass:"red-title"},[_vm._v(" Sin conectar")]):_vm._e(),(_vm.satLink)?_c('b',{staticClass:"green-title"},[_vm._v(" Cuenta conectada")]):_vm._e()])])]),_c('div',{staticClass:"global-list-main-container"},[_c('div',{staticClass:"table-header"},[_vm._v(" Cantidad de documentos: "),_c('b',[_vm._v(_vm._s(_vm.formattedDocuments.length))])]),_c('b-table',{ref:"incomesaDocuments",attrs:{"pagination-position":"both","data":_vm.formattedDocuments,"striped":true,"bordered":true,"hoverable":true,"checked-rows":_vm.checkedClients,"paginated":"","current-page":_vm.currentPage,"per-page":_vm.perPage,"opened-detailed":_vm.defaultOpenedDetails,"selected":_vm.selected,"detailed":"","detail-key":"_id","sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort-direction":"asc","checkbox-position":"left","scrollable":"","default-sort":"ID_IDENTIFICACION"},on:{"update:checkedRows":function($event){_vm.checkedClients=$event},"update:checked-rows":function($event){_vm.checkedClients=$event},"update:selected":function($event){_vm.selected=$event},"details-open":function (row) { return _vm.$buefy.toast.open({
            message: ("Información de " + (row.ID_IDENTIFICACION)),
            type: 'is-success',
            position: 'is-bottom',
          }); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("No hay documentos")])]},proxy:true},{key:"default",fn:function(props){return _vm._l((_vm.columns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"searchable":"","sortable":"","visible":column.display,"label":column.label,"custom-sort":column.customSort}},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s("Sin información"))]):_vm._e(),(column.money && _vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s(_vm._f("money")(props.row[column.field],"MXN", 2)))]):_vm._e(),(!column.money && _vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s(props.row[column.field]))]):_vm._e()])})}},{key:"detail",fn:function(props){return [_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"})]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"controls"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.showXML(props.row._id)}}},[_vm._v("Ver XML")]),(props.row.DOC_DET && props.row.DOC_DET.length > 0)?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openDocumentDetails(props.row)}}},[_vm._v("Ver detalles")]):_vm._e(),(props.row.PAGOS && props.row.PAGOS.length > 0)?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openDocumentPayments(props.row)}}},[_vm._v("Ver pagos")]):_vm._e(),(props.row.NOMINA && _vm.typeName === 'Nómina')?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openDocumentPayroll(props.row)}}},[_vm._v("Ver detalles de nomina")]):_vm._e()],1)])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }