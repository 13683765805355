<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Documentos SAT </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}

      <b-field grouped group-multiline class="global-lists-controls no-border">
        <div class="control">
          <b-tooltip
            label="Solo debes realizar la conexión con el SAT una vez, si ya realizate este paso no es necesario repetirlo"
            multilined
          >
            <b-button type="is-primary" @click="openConnectSat" v-if="!satLink"
              >Conectar cuenta del SAT</b-button
            >
          </b-tooltip>
        </div>
        <div class="control" v-if="satLink">
          <b-button type="is-primary" @click="openSynchDocument()"
            >Sincronizar documentos</b-button
          >
        </div>
      </b-field>
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Ingreso" icon="bank-transfer-in">
        <SatDocumentsList :documents="incomeDocuments" typeName="Ingreso" />
      </b-tab-item>
      <b-tab-item label="Egreso" icon="bank-transfer-out">
        <SatDocumentsList :documents="expenseDocuments" typeName="Egreso" />
      </b-tab-item>
      <b-tab-item label="Pago" icon="credit-card-outline">
        <SatDocumentsList :documents="paymentDocuments" typeName="Pago" />
      </b-tab-item>
      <b-tab-item label="Nómina" icon="account-hard-hat">
        <SatDocumentsList :documents="salaryDocuments" typeName="Nómina" />
      </b-tab-item>
      <b-tab-item label="Traslado" icon="truck-outline">
        <SatDocumentsList :documents="transferDocuments" typeName="Traslado" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ConnectSatModal from "../components/ConnectSatModal";
import SynchDocuments from "../components/SynchDocuments";
import SatDocumentsList from "../components/SatDocumentsList";
// @ is an alias to /src
export default {
  name: "DocumentsList",
  components: {
    SatDocumentsList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "accounting");
  },
  async created() {
    let allPromises = [];
    if (this.$store.getters.EXPENSEDOCUMENTS.length === 0) {
      allPromises.push(
        this.$store.dispatch("GETDOCUMENTS", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 15
          ),
          endDate: new Date(),
          type: "Egreso",
        })
      );
    }

    if (this.$store.getters.PAYMENTDOCUMENTS.length === 0) {
      allPromises.push(
        this.$store.dispatch("GETDOCUMENTS", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 15
          ),
          endDate: new Date(),
          type: "Pago",
        })
      );
    }

    if (this.$store.getters.SALARYDOCUMENTS.length === 0) {
      allPromises.push(
        this.$store.dispatch("GETDOCUMENTS", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 15
          ),
          endDate: new Date(),
          type: "Nómina",
        })
      );
    }

    if (this.$store.getters.INCOMEDOCUMENTS.length === 0) {
      allPromises.push(
        this.$store.dispatch("GETDOCUMENTS", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 15
          ),
          endDate: new Date(),
          type: "Ingreso",
        })
      );
    }

    if (this.$store.getters.TRANSFERDOCUMENTS.length === 0) {
      allPromises.push(
        this.$store.dispatch("GETDOCUMENTS", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 15
          ),
          endDate: new Date(),
          type: "Traslado",
        })
      );
    }
    allPromises.push(this.$store.dispatch("GETSATLINK"));
    await Promise.all(allPromises);
  },
  methods: {
    openConnectSat() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ConnectSatModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openSynchDocument() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SynchDocuments,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    incomeDocuments() {
      return this.$store.getters.INCOMEDOCUMENTS.map((singleExpense) => {
        const singleExpenseWithProps = { ...singleExpense };
        return singleExpenseWithProps;
      });
    },
    expenseDocuments() {
      return this.$store.getters.EXPENSEDOCUMENTS.map((singleExpense) => {
        const singleExpenseWithProps = { ...singleExpense };
        return singleExpenseWithProps;
      });
    },
    paymentDocuments() {
      return this.$store.getters.PAYMENTDOCUMENTS.map((singleExpense) => {
        const singleExpenseWithProps = { ...singleExpense };
        return singleExpenseWithProps;
      });
    },
    transferDocuments() {
      return this.$store.getters.TRANSFERDOCUMENTS.map((singleExpense) => {
        const singleExpenseWithProps = { ...singleExpense };
        return singleExpenseWithProps;
      });
    },
    salaryDocuments() {
      return this.$store.getters.SALARYDOCUMENTS.map((singleExpense) => {
        const singleExpenseWithProps = { ...singleExpense };
        return singleExpenseWithProps;
      });
    },
    satLink() {
      return this.$store.getters.SAT_LINK;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
