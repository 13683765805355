<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Pagos de documento de {{ typeName }}
    </h1>

    <div class="invoice-div-bottom-div">
      <div class="table-header providers-list-global table-header-border">
        Cantidad de pagos: <b>{{ currentPayments.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="currentPayments"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="documentPayments"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información del pago`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="formattedDate"
      >
        <template #empty>
          <div class="has-text-centered">No hay pagos</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div
                  class="table-header providers-list-global table-header-border"
                >
                  Cantidad de documentos relacionados:
                  <b>{{ props.row.DOCUMENTOS_RELACIONADOS.length }}</b>
                </div>
                <b-table
                  pagination-position="both"
                  :data="props.row.DOCUMENTOS_RELACIONADOS"
                  :striped="true"
                  :bordered="true"
                  :hoverable="true"
                  ref="retentionDetails"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort-direction="asc"
                  checkbox-position="left"
                  scrollable
                >
                  <template #empty>
                    <div class="has-text-centered">
                      No hay documentos relacionados
                    </div>
                  </template>

                  <template slot-scope="props">
                    <b-table-column
                      v-for="column in relationsColumns"
                      searchable
                      sortable
                      v-bind="column"
                      :key="column.field"
                      :visible="column.display"
                      :label="column.label"
                    >
                      <span v-if="!checkIfValid(props.row[column.field])">{{
                        "Sin información"
                      }}</span>
                      <span v-if="column.money">{{
                        props.row[column.field] | money("MXN", 2)
                      }}</span>
                      <span v-if="!column.money">{{
                        props.row[column.field]
                      }}</span>
                    </b-table-column>
                  </template>
                </b-table>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "SatDoumentsPayments",
  props: ["payments", "typeName"],
  data() {
    return {
      currentObjects: [],
      currentMovements: [],
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "formattedDate",
          label: "Fecha",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TIPO_PAGO",
          label: "Tipo de pago",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "MONEDA_CAMBIO",
          label: "Tipo de cambio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "FOLIO_OPE",
          label: "Folio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "RFC_BENEF",
          label: "RFC Benefeciario",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CUENTA_BENEF",
          label: "Cuenta del beneficiario",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "RFC_PAGA",
          label: "RFC del pago",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CUENTA_PAGA",
          label: "Cuenta del pago",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_BANCO_PAGA",
          label: "Banco del pago",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      relationsColumns: [
        {
          field: "ID_IDENTIFICACION",
          label: "Id",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "METODO_PAGO",
          label: "Método de pago",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NUMERO_PARCIALIDAD",
          label: "Número de parcialidad",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "SALDO_PASADO",
          label: "Saldo pasado",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "CANTIDAD_PAGADA",
          label: "Cantidad pagada",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "RESTA",
          label: "Saldo restante",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
    };
  },
  mounted() {
    moment.locale("es");
  },
  methods: {},
  computed: {
    currentPayments() {
      return this.payments.map((singlePayment) => {
        const singlePaymentWithProps = { ...singlePayment };
        singlePaymentWithProps.formattedDate = moment(
          singlePayment.FECHA
        ).format("DD-MM-YYYY HH:mm");
        return singlePaymentWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
